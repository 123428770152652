import { Auth, AxiosTransport, Directus, LocalStorage } from '@directus/sdk'
// const url = process.env.production ? 'https://citygates.logexpert.ru' : 'http://localhost:8055'
const url = 'https://citygates.logexpert.ru'

/**
 * @type {Directus}
 */
let instance

const state = () => ({

})

const getters = {
    url: () => {
        return url
    },

    instance: () => {
        if(!instance) {
            const storage = new LocalStorage()

            const transport = new AxiosTransport(url, storage, async () => {
                await auth.refresh(); // This is how axios checks for refresh
            })

            const auth = new Auth(transport, storage, {
                mode: 'json',
            })

            instance = new Directus(url, {
                auth,
                storage,
                transport,
            })
        }

        return instance
    },

    authenticated: (getters) => {
        return !!getters.instance.auth.token
    }
}

const actions = {

}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}