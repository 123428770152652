<template>
  <q-layout view="hhh LpR lff">
    <q-header elevated style="background-color: #7f1c96">
      <q-toolbar>
        <q-btn flat icon="menu" @click="clickMenu"/>

        <q-toolbar-title>
          Автоматический доступ спецтранспорта
        </q-toolbar-title>
        <q-space/>

        <div class="main-notification">
          В целях безопасности, открывать шлагбаумы можно только в присутствии экипажа на месте
        </div>

        <q-btn icon="logout" flat @click="logout"/>
      </q-toolbar>
    </q-header>

    <q-drawer v-if="markers.length > 0" overlay v-model="drawerLeft">
      <q-input class="absolute-top" outlined v-model="searchItems" label="Поиск"
      >
        <template v-slot:prepend>
          <q-icon name="search"/>
        </template>

        <template v-if="searchItems" v-slot:append>
          <q-icon name="close" class="cursor-pointer" @click="searchItems = null"/>
        </template>
      </q-input>

      <q-scroll-area class="item-list">
        <q-list bordered separator padding>
          <q-item v-for="m in filteredItems" :key="m.id"
                  clickable
                  @click="() => clickItemMarker(m)"
                  :disable="!m.latitude || !m.longitude"
          >
            <q-item-section>
              <q-item-label>
                <div class="row">
                  <div class="col-1" v-if="isOffline(m)">
                    <q-icon name="warning" color="red"/>
                  </div>
                  <div class="col-1" v-else>
                    <q-icon name="radio_button_checked" :color="statusColor(m)"/>
                  </div>
                  <div class="col">
                    [{{m.number}}] {{m.address}}
                  </div>
                </div>
              </q-item-label>
              <q-item-label caption>
                {{m.company}} {{getState(m.id)}}
              </q-item-label>
              <q-item-label caption>
                {{m.brand}}
              </q-item-label>
              <q-item-label caption>
                <div class="row">
                  <div>Автоматическое закрытие:
                    <span v-if="m.auto_close === null"> неизвестно</span>
                    <span v-if="m.auto_close === true"> да</span>
                    <span v-if="m.auto_close === false"> нет</span>
                  </div>
                </div>
              </q-item-label>
              <q-item-label caption>
                <div class="row">
                  <div>Закрытие той же кнопкой:
                    <span v-if="m.manual_close === null"> неизвестно</span>
                    <span v-if="m.manual_close === true"> да</span>
                    <span v-if="m.manual_close === false"> нет</span>
                  </div>
                </div>
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-scroll-area>
    </q-drawer>

    <q-page-container v-if="show">
      <q-page>
        <l-map class="_map" ref="map" :zoom="zoom" :center="center" @ready="readyMap"
               :options="mapOptions"
        >
          <l-tile-layer :url="url"/>
          <l-control-zoom position="topright"/>
          <v-marker-cluster :options="clusterOptions" ref="clusters"
                            @clustermouseover="mouseoverCluster"
          >
            <l-marker v-for="m in markers_valid" :key="m.id"
                      :lat-lng="[m.latitude, m.longitude]"
                      :icon="getMarkerIcon(m)"
                      @click="clickMarker"
                      ref="markers"
                      :marker_id="m.id"
            >
              <marker-popup :marker="m" :status="getStatus(m)"/>
            </l-marker>
          </v-marker-cluster>
        </l-map>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
import { LMap, LTileLayer, LMarker, LControlZoom } from 'vue2-leaflet'
import L from 'leaflet'
import _ from 'lodash'
// eslint-disable-next-line no-unused-vars
import moment from 'moment'

import Vue2LeafletMarkercluster from 'vue2-leaflet-markercluster'

import 'leaflet/dist/leaflet.css'

import MarkerPopup from "@/components/MarkerPopup"
import { mapGetters } from 'vuex'

let debouncedUpdateMarkerStates
let refreshIntervalId

export default {
  name: "Home",
  components: {
    MarkerPopup,
    LMap, LTileLayer, LMarker, LControlZoom,
    'v-marker-cluster': Vue2LeafletMarkercluster
  },

  data() {
    return {
      url: 'https://osm.logexpert.ru/tile/{z}/{x}/{y}.png',
      // url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      center: L.latLng(50.599740116245606, 36.580029783321564),
      zoom: 13,
      markers: [],
      clusterOptions: {
        maxClusterRadius: 35
      },
      mapOptions: {
        zoomControl: false
      },
      drawerLeft: true,
      searchItems: null,
      gatesConfig: null,
      deviceStates: [],
      show: false
    }
  },

  async mounted() {
    try {
      const gatesConfigResult = await this.server.items('gates_config').readMany()
      console.log('gates config result:', gatesConfigResult)
      this.gatesConfig = gatesConfigResult.data
    }
    catch (e) {
      // console.error('config error', e)
      this.$q.notify({
        message: `Не удалось загрузить конфигурацию: ${e.message}`,
        color: 'red'
      })
    }

    await this.fetchMarkers()

    const updateMarkerStates = this.updateMarkerStates
    await updateMarkerStates()

    debouncedUpdateMarkerStates = _.debounce(updateMarkerStates, 5000, {
      maxWait: 10000
    })

    refreshIntervalId = setInterval(debouncedUpdateMarkerStates, 10000)

    this.show=true
  },

  // на всякий случай перед дестроем чистим таймер
  beforeDestroy() {
    console.log('destroy refresh timer')
    clearInterval(refreshIntervalId)
  },

  computed: {
    markers_valid() {
      return this.markers.filter(m => m.latitude && m.longitude)
    },

    sortedItems() {
      return _.sortBy(this.markers, (x) => x.number)
    },

    filteredItems() {
      return !this.searchItems
          ? this.sortedItems
          : this.sortedItems.filter(x => x.address.toLowerCase().includes(this.searchItems.toLowerCase())
              || x.number.toString().includes(this.searchItems)
              || x.company.toLowerCase().includes(this.searchItems.toLowerCase()))
    },

    ...mapGetters('server', {
      server: 'instance'
    })
  },

  methods: {
    mouseoverCluster(/*event*/) {
      // let markersInside = event.layer.getAllChildMarkers()
      // let ids = markersInside.map(x => x._leaflet_id)
      // let mapMarkers = this.$refs.markers.filter(m => ids.includes(m.mapObject._leaflet_id))
      // let markers = mapMarkers.map(m => this.markers.find(x => x.id === m.$attrs.marker_id))
      // console.log(markers)
    },

    // eslint-disable-next-line no-unused-vars
    // statusIcon(m) {
    //   // check_circle
    //   const status = this.getStatus(m)
    //   return status.online ? 'radio_button_checked' : 'check_circle'
    // },

    // eslint-disable-next-line no-unused-vars
    statusColor(m) {
      const status = this.getStatus(m)
      return status.online ? 'green' : 'grey'
    },

    getStatus(marker) {
      return this.deviceStates.find(s => s.gate === marker.id)
    },

    // eslint-disable-next-line no-unused-vars
    isOffline(marker) {
      const status = this.getStatus(marker)
      if(!status) return true
      if(!status.server_timestamp) return true
      return new Date() - new Date(status.server_timestamp) >= 2 * 60 * 60 * 1000
    },

    async logout() {
      try {
        await this.server.auth.logout()
        await this.$router.push({ name: 'Login' })
      }
      catch (e) {
        this.$q.notify({
          message: `Ошибка выхода: ${e.message}`,
          color: 'red'
        })
      }
    },

    readyMap() {
      this.map = this.$refs.map.mapObject
    },

    clickMarker(event) {
      this.$nextTick(() => event.target.openPopup())
    },

    async fetchMarkers() {
      let result

      try {
        result = await this.server.items('gate').readMany({
          limit: -1
        })
      }
      catch (e) {
        // console.error('fetch markers', e)
        this.$q.notify({
          message: `Не удалось загрузить шлагбаумы: ${e.message}`,
          color: 'red'
        })
        return
      }

      const data = result.data
      console.log('fetch gates x', data.length)
      this.markers = data
    },

    // todo: добавить обновление маркеров которые изменились с последнего обновления
    // async updateMarkers() {
    //   this.server.items('gate').readMany({
    //     limit: -1,
    //
    //   })
    // },

    async updateMarkerStates() {
      console.log('call updateMarkerStates')
      const result = await this.server.transport.get(`/gates/status`)

      console.log('updateMarkerStates result:', result)
      this.deviceStates = result.data
    },

    getMarkerIcon(marker) {
      const gate = this.markers.find(x => x.id === marker.id)
      const state = this.deviceStates.find(x => x.gate === marker.id)
      console.log(state)
      let icon_id

      if (state.online === true) {
        icon_id = gate.type === 'шлагбаум' ? this.gatesConfig.online_icon : this.gatesConfig.online_icon2
      }
      if (state.online === false) {
        icon_id = gate.type === 'шлагбаум' ? this.gatesConfig.offline_icon : this.gatesConfig.offline_icon2
      }
      if (state.online === null) {
        icon_id = gate.type === 'шлагбаум' ? this.gatesConfig.not_connected_icon : this.gatesConfig.not_connected_icon2
      }



      // select_icon: {
      //   if(!state) break select_icon
      //   if(!state.server_timestamp) break select_icon
      //   const date = new Date(state.server_timestamp)
      //   const seconds = (Date.now() - date) / 1000
      //
      //   icon_id = seconds >= 300
      //       ? ( gate.type === 'шлагбаум' ? this.gatesConfig.offline_icon : this.gatesConfig.offline_icon2 )
      //       : ( gate.type === 'шлагбаум' ? this.gatesConfig.online_icon : this.gatesConfig.online_icon2 )
      // }



      return L.icon({
        iconUrl: `${this.server.transport.url}/assets/${icon_id}`,
        iconSize: [this.gatesConfig.size_icon, this.gatesConfig.size_icon]
      })
    },

    clickMenu() {
      this.drawerLeft = !this.drawerLeft
    },

    clickItemMarker(marker) {
      let m = this.$refs['markers'].find(x => x.$attrs.marker_id === marker.id)

      let center = L.latLng(marker.latitude, marker.longitude)
      let zoom = 20

      this.map.setView(center, zoom)

      //todo: надо как-то по другому обработать, сейчас костыль
      setTimeout(() => m.mapObject.openPopup(), 200)
    },

    getGateTimeStamp(id) {
      const date = this.deviceStates.find(x => x.gate === id)?.server_timestamp
      if(!date) return 'никогда'
      return moment(date).fromNow()
    },

    /**
     *
     * @param id {Number}
     * @return {string}
     */
    getState(id) {
      const state = this.deviceStates.find(x => x.gate === id)
      if(state == null) return 'никогда не был на связи'

      return `последняя активность ${moment(state.server_timestamp).fromNow()}`
    }
  }
}
</script>

<style scoped>
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

._map {
  height: calc(100vh - 50px);
}

.item-list{
  margin-top: 56px;
  height: calc(100vh - 106px);
  border-right: 1px solid #ddd;
}

.main-notification {
  font-weight: 700;
  padding: 5px;
  color: red;
  background-color: white;
  border-radius: 5px;
}
</style>
