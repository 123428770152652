import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "@/layouts/Home";
import Login from "@/layouts/Login";

import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'Доступ спецтранспорта' }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { title: 'Вход' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach(to => {
  if(to.meta.title) document.title = to.meta.title
})

router.beforeEach(async (to, from, next) => {
  const instance = store.getters["server/instance"]
  const authenticated = instance.auth?.token

  console.log('auth', instance.auth)

  if(to.name === 'Login' && authenticated) {
    console.log('cannot go to login with authenticated')
    next({ name: 'Home' })
  }
  else if(to.name !== 'Login' && !authenticated) {
    console.log('no authenticated, go to login page')
    next({ name: 'Login' })
  }
  else next()
})

export default router
