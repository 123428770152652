<template>
  <q-layout>
<!--    <div v-if="loginProcess" class="flex flex-center">-->
<!--      <q-circular-progress indeterminate size="70px" color="primary"/>-->
<!--    </div>-->

<!--    <div v-else class="row justify-center">-->
<!--      <div class="col-3">-->
<!--        <q-form class="q-gutter-md" @submit="submit">-->
<!--          <q-input type="login" v-model="login" label="Пользователь"/>-->
<!--          <q-input type="password" v-model="password" label="Пароль"/>-->

<!--          <div>-->
<!--            <q-btn label="Войти" type="submit" :disable="loginProcess" color="primary"/>-->
<!--          </div>-->
<!--        </q-form>-->
<!--      </div>-->
<!--    </div>-->

    <div v-if="loginProcess" class="flex flex-center" style="height: 100vh">
      <q-circular-progress indeterminate size="70px" color="primary"/>
    </div>
    <div v-else class="row items-center justify-center" style="min-height: 100vh">
      <div class="col-3">
        <q-form @submit="submit" style="padding: 40px 40px; background-color: #ffffff; border-radius: 5px;">
          <div class="background-logo"></div>
          <div class="text-h5" style="margin-bottom: 15px">Автоматический доступ спецтранспорта</div>
          <q-input class="margin-for-input" outlined type="login" v-model="login" label="Пользователь" :rules="[ val => val && val.trim().length > 0 || 'Пожалуйста укажите пользователя']"/>
          <q-input class="margin-for-input" outlined :type="isPwd ? 'password' : 'text'" v-model="password" label="Пароль" :rules="[ val => val && val.trim().length > 0 || 'Введите пароль']">
            <template v-slot:append>
              <q-icon
                  :name="isPwd ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="isPwd = !isPwd"
              />
            </template>
          </q-input>
          <div>
            <q-btn label="Войти" type="submit" :disable="loginProcess" color="primary"/>
          </div>
        </q-form>
      </div>
    </div>

  </q-layout>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "Login",
  data() {
    return {
      loginProcess: false,
      login: null,
      password: null,
      isPwd: true,
    }
  },

  methods: {
    async submit() {
      this.loginProcess = true
      // setTimeout(() => this.loginProcess = false, 2000)

      const login = this.login
      const password = this.password

      try {
        await this.server.auth.login({
          email: login,
          password
        }, { refresh: { auto: true, time: 30000 } })
      }
      catch (e) {
        this.$q.notify({
          message: `Ошибка входа: ${e.message}`,
          color: 'red'
        })

        console.error('error', e.message)
        this.loginProcess = false
        return
      }

      await this.$router.push({ path: '/' })
    }
  },

  computed: {
    ...mapGetters({
      server: 'server/instance'
    })
  }
}
</script>

<style scoped>
.margin-for-input {
  margin-bottom: 10px;
}
.background-logo {
  width: 100%;
  height: 50px;
  background-image: url("../assets/logo-original.png");
  background-repeat: no-repeat;
  background-position: 0% 100%;
  background-size: contain;
  margin-bottom: 10px;
}
/*.automatic-span {*/
/*  font-size: 14px;*/
/*}*/
</style>