<template>
  <l-popup style="min-width: 200px">
    <div>
      <div v-if="marker.number">Номер: {{marker.number}}</div>
      <div>Название: {{marker.address}}</div>
      <div v-if="marker.company">Компания: <b>{{marker.company}}</b></div>
      <div v-if="marker.brand">Марка шлагбаума: {{marker.brand}}</div>
      <div>Автоматическое закрытие:
        <span v-if="marker.auto_close === null"> неизвестно</span>
        <span v-if="marker.auto_close === true"> да</span>
        <span v-if="marker.auto_close === false"> нет</span>
      </div>
      <div>Закрытие той же кнопкой:
        <span v-if="marker.manual_close === null"> неизвестно</span>
        <span v-if="marker.manual_close === true"> да</span>
        <span v-if="marker.manual_close === false"> нет</span>
      </div>

      <div class="row" style="margin-top: 5px">
        <div class="col">
          <q-btn @click="openGate" color="primary" size="xs" padding="xs lg">
            открыть
          </q-btn>
        </div>

<!--        <div v-if="status !== null && status !== undefined" class="col">-->
<!--          статус: {{ status.is_open ? 'открыт' : 'закрыт' }}-->
<!--        </div>-->
      </div>
    </div>
  </l-popup>
</template>

<script>
import { LPopup } from 'vue2-leaflet'
import {mapGetters} from "vuex";

export default {
  name: "MarkerPopup",
  components: {
    LPopup
  },
  props: {
    marker: {
      type: Object,
      required: true
    },

    status: {
      type: Object
    }
  },

  computed: {
    ...mapGetters({
      'server': "server/instance"
    })
  },

  methods: {
    async openGate() {
      const id = this.marker.id

      if(!!this.status && !this.status.online && (new Date() - new Date(this.status.server_timestamp)) >= 2 * 60 * 60 * 1000) {

        this.$q.notify({
          message: `Шлагбаум ${this.marker.number} не на связи продолжительное время, возможно отсутствие управления`,
          color: 'warning',
          textColor: 'black'
        })
      }

      try {
        const result = await this.server.transport.get(`/gates/${id}/open`)

        this.$q.notify({
          message: `Команда на открытие выполнена ${this.marker.number}`,
          color: 'primary'
        })

        console.log('opening result', result)
      }
      catch (e) {
        this.$q.notify({
          message: `Не удалось открыть ${this.marker.number}: ${e.message}`,
          color: 'red'
        })
      }
    }
  }
}
</script>

<style scoped>

</style>